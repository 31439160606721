import { Component, useCallback, useEffect, useState, useMemo } from 'react';
import { postData, postFile, updateData, deleteItem, customTableStyles } from '../utils/constants';
import { FaPlus, FaPencil, FaXmark, FaFileInvoice, FaFileExcel, FaTrashCan, FaPenToSquare, FaLayerGroup, FaChevronLeft, FaFile } from "react-icons/fa6";
import { FaFilePdf, FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Modal, Row, Tabs, Tab, Table, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';


class Issues extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],      
      isLoading : true,
      show : false,
      show_content: "form"    
    };  
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.view = this.view.bind(this);
    this.delete = this.delete.bind(this);
  }

  async getIssues(){
    try {
      const response = await fetch('/api/issues');
      const json = await response.json();
      this.setState({ data:json.length?json:[] });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  add() {
    this.setState({ show: true, show_content: "form"});
  }

  edit(Id) {    
    const issues = this.state.data;
    const selectedIssue = issues.filter(e=> e.id === Id)[0];
    this.setState(selectedIssue);
    this.setState({ show: true, show_content:"form" });
  }

  view(row, event) {    
    const issues = this.state.data;
    const selectedIssue = issues.filter(e=> e.id === row.id)[0];
    this.setState(selectedIssue);
    this.setState({ show: true, show_content:"" });
  }


  delete(Ids) {        
    deleteItem(`/api/issues?docRef=[${Ids}]`)
    .then((response) => {
      toast.info(response.message);
      this.getVendors();
    });
  }      

  componentDidMount() {
    this.getIssues();
  }

  render() {
    if(!this.state.isLoading) {
      const data = this.state.data;
      return(
        <>
          {!this.state.show?(
            <>
              <IssueList data={data} edit={this.edit} view={this.view} />  
              <div className="position-relative">
                <Button type="button" variant="primary" className="fw-semibold position-fixed bottom-0 end-0 mx-5 my-4" onClick={this.add}>
                  <FaPlus className="text-light fs-4 me-1" />
                  Add Outward
                </Button>
            </div>
            </>
            ):(this.state.show_content==="form"?(
                <IssueForm data={this.state} refresh={()=> this.getIssues()} close={()=> this.setState({show: false})} />
              ):(
                <ViewIssue data={this.state} close={()=> this.setState({show: false})} />
              )
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

function IssueList(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  const filteredItems = props.data.filter(item => {
    const txnNoMatches = item.txn_no && item.txn_no.toLowerCase().includes(filterText.toLowerCase());
    const partyMatches = item.party && item.party.toLowerCase().includes(filterText.toLowerCase());    

    return txnNoMatches || partyMatches
  });

  const actionComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const XLSXDownload = () => {
      var worksheet = XLSX.utils.json_to_sheet(filteredItems);
      const workbook = XLSX.utils.book_new();
      const filename = `OMS_Installer_Issues_${moment().format('YYYYMMDD')}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }    

    return (
      <div className="d-flex justify-content-end align-items-center">
        <InputGroup size="sm" className="px-2">
          <Form.Control name="fromDate" type="date" className="shadow-none" /> 
          <Form.Control name="toDate" type="date" className="shadow-none" />          
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FaSearch />
          </button>
        </InputGroup>        
        <InputGroup size="sm" className="px-2">
          <Form.Control
            type="text" 
            className="shadow-none w-25" 
            placeholder="Search by Order No., Party" 
            aria-label="Search area"          
            value={filterText} 
            onChange={e => setFilterText(e.target.value)}              
            aria-describedby="inputGroup-sizing-default"
          />
          <button className="btn btn-light border" type="button" onClick={handleClear}>
            <FaXmark />
          </button>  
        </InputGroup>        
        <div>
          <button className="btn btn-sm btn-outline-success mx-2 d-flex align-items-center" type="button" onClick={XLSXDownload}>
            <FaFileExcel className="pe-2 fs-5" /> Export
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [      
    {
        name: 'Order Primary No',
        selector: row => row.order_no,
        sortable: true,
    },
    {
        name: 'Order Date',
        selector: row => row.order_date,
        sortable: true,
    },      
    {
        name: 'Consumer',
        selector: row => row.consumer,
        sortable: true,
    },      
    {
      name: 'Project Code',
      selector: row => row.project_code,
      sortable: true,
    },      
    {
      name: 'Project Name',
      selector: row => row.project_name,
      sortable: true,
    },             
  ];

  const data = filteredItems.map((item, index)=>{
    return {
      id: item.id,      
      order_no: item.primary_no,
      order_date: moment(item.txn_date).format("DD-MM-YYYY"),
      consumer: item.farmer_name,
      project_code: item.project_code,
      project_name: item.project_name
    }
  });

  const THeader = ()=> {
    return(
      <>
        <h4 className="mb-0 text-secondary"> Outwards</h4> 
      </>
    );
  }

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      confirmAlert({
        title: 'Delete confirmation',
        message: `Are you sure you want to delete:\r ${selectedRows.map(r => r.order_no)}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              var Ids = selectedRows.map(r => r.id);              
              props.delete(Ids);
              setToggleCleared(!toggleCleared);
            }
          },
          {
            label: 'No',
            onClick: () => console.log("Delete operation cancelled.")
          }
        ]
      });        
        // setData(differenceBy(data, selectedRows, 'title'));            
    };

    return (
      <button key="delete" className="btn btn-sm btn-danger" onClick={handleDelete}>
        <FaTrashCan /> Delete
      </button>
    );
  }, [data, selectedRows, toggleCleared]);   
  
  return(
    <>      
      <div className="my-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}                        
            columns={columns}
            data={data}
            actions={actionComponentMemo}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll                                    
            selectableRows
            selectableRowsHighlight
            selectableRowsRadio="checkbox"
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared} 
            onRowClicked={props.view}                         
          />
        </div>
      </div>
    </>
  );
}

function IssueForm(props) {
  let productObj = {products:[], product_id:"", product_name:"", category:"", serial_no:"", units:"", uom: ""}
  const [farmers, setFarmers] = useState([]);
  const [farmerSearchVal, setFarmerSearchVal] = useState("");
  const [filteredFarmers, setFilteredFarmers] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [installers, setInstallers] = useState([]);
  const [bos, setBOS] = useState({id: "", bos_name:"", products:[]});
  const [products, setProducts] = useState([]);
  const [issue, setIssue] = useState({});
  const [lineItems, setLineItems] = useState(Array(12).fill(productObj));
  const [selectedOrder, setSelectedOrder] = useState({
    order_id    : "",
    primary_no: "",
    project_code: "",
    project_name: "",
    invoice_no  : "",
    invoice_date: "",
    farmer_name : "",
    village     : "",
    taluka      : "",
    district    : "",
    state       : "",  
    mobile_number: ""
  });
  const [key, setKey] = useState('products');
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getFarmers = async()=> {
    try{
      const response = await fetch("/api/farmers");
      var json = await response.json();
      //replicate this data 20 times
      //json = Array(20).fill(json[0]).map((item, index) => ({ ...item, id: index }));
      setFarmers(json.length?json:[]);
    }catch(e){
      toast.error(e);
    }
  }

  const getInstallers = async()=> {
    try{
      const response = await fetch("/api/installers");
      const json = await response.json();
      setInstallers(json.length?json:[]);
    }catch(e){
      console.error(e);
    }
  }

  const getStates = async()=> {
    try{
      const response = await fetch("/api/states");
      const json = await response.json();
      setStates(json.length?json:[]);
    }catch(e){
      console.error(e);
    }
  }

  const getDistricts = async()=> {
    try{
      const response = await fetch("/api/districts");
      const json = await response.json();
      setDistricts(json.length?json:[]);
    }catch(e){
      console.error(e);
    }
  }  

  const getBOS = async(orderId)=> {
    try{
      const response = await fetch(`/api/bos/${orderId}`);
      const json = await response.json();
      console.log("bos: ", json);
      setBOS(json.id?json:bos);    
      if((json.id) && (json.lineItems.length)){        
        setProducts(json.products.length?json.products:[]);
        const bosItems = json.lineItems;
        const updatedItems = lineItems.map((item, index) => (index < bosItems.length ? { 'products': bosItems[index].products, 'product_id': bosItems[index].product_id, 'product_name': bosItems[index].product_name, 'category':bosItems[index].product_category, 'serial_no': '', 'units': bosItems[index].units, 'uom': bosItems[index].uom } : item));
        console.log("lineItems: ", updatedItems);
        setLineItems(updatedItems);      
      }else{
        toast.error(json.message);
        setProducts([]);
        setLineItems(Array(12).fill(productObj));
      }      
    }catch(e){
      console.error(e);
      toast.error(e);
    }finally{
      setLoading(false);
    }
  }

  const restoreBOS =()=> {
    setBOS({id: "", bos_name:"", products:[]});
    setLineItems(Array(12).fill(productObj));
    setProducts([]);
    setSelectedOrder({
      order_id : "",
      primary_no: "",
      project_code: "",
      project_name: ""
    });
  }

  const handleInputChange = (event)=> {
    const name = event.target.name;
    const value = event.target.value;
    setIssue((prevIssue) => ({
      ...prevIssue,
      [name]: value,
    }));  
    if(name==="farmer_id"){
      if(value){
        const selectedFarmer = farmers.filter(e=> e.primary_no===value);
        if(selectedFarmer.length){
          getBOS(selectedFarmer[0].id);
          setSelectedOrder({
            order_id    : selectedFarmer[0].id,
            primary_no  : value,
            project_code: selectedFarmer[0].project_code,
            project_name: selectedFarmer[0].project_name,
            invoice_no  : selectedFarmer[0].invoice_1_no+", "+selectedFarmer[0].invoice_2_no,
            invoice_date: selectedFarmer[0].invoice_date,
            farmer_name : selectedFarmer[0].farmer_name,
            village     : selectedFarmer[0].village,
            taluka      : selectedFarmer[0].taluka,
            district    : selectedFarmer[0].district,
            state       : selectedFarmer[0].state,
            mobile_number: selectedFarmer[0].mobile_number
          });
          setIssue((prevIssue) => ({
            ...prevIssue,
            [name]: selectedFarmer[0].id,
            invoice_no: selectedFarmer[0].invoice_1_no+", "+selectedFarmer[0].invoice_2_no,
            invoice_date: selectedFarmer[0].invoice_date,
            installer_id: selectedFarmer[0].installer_id,
            installer_code: selectedFarmer[0].installer_code,
            installer_name: selectedFarmer[0].installer_name,
          }));          
        }else{
          restoreBOS();          
        }        
      }else{
        restoreBOS();
      }     
    }
    if(name==="state_code" && value){
      let stateName = states.filter(e=> e.state_code==value)[0].state_name.replaceAll(' ','').toLocaleLowerCase();
      setIssue((prevIssue) => ({
        ...prevIssue,
        'state_name': stateName
      })); 
    }
    if(name==="district_code" && value){
      let districtName = districts.filter(e=> e.district_code==value)[0].district_name.replaceAll(' ','').toLocaleLowerCase();
      setIssue((prevIssue) => ({
        ...prevIssue,        
        'district_name': districtName
      }));
      const districtFarmers = farmers.filter(e=> e.state.replaceAll(' ','').toLocaleLowerCase()===issue.state_name).filter(e=> e.district.replaceAll(' ','').toLocaleLowerCase()===districtName);
      setFilteredFarmers(districtFarmers);
    }
  }

  const handleLineItemsChange = (e)=>{
    const name = e.target.name;
    const value = e.target.value;
    const index = parseInt(e.target.attributes.dataindex.value);
    
    const updatedLineItems = [...lineItems];
    if(name==="product_id"){
      console.log("productId: ", value);
      const filteredProducts = products.filter(e=> e.product_id=== parseInt(value));
      if(filteredProducts.length){
        const existingItem = lineItems.filter(e=> e.product_id===parseInt(value));
        if(existingItem.length){
          toast.warning("Item is already in the list.")
        }else{
          updatedLineItems[index] = {
            ...updatedLineItems[index],
            [name]       : parseInt(value),
            product_name : filteredProducts[0].product_name,            
            uom          : filteredProducts[0].uom
          };        
        }        
      }else{
        updatedLineItems[index] = { products:[], product_id:"", product_name:"", category:"", serial_no:"", units:"", uom:"" };
      }
    }else{
      updatedLineItems[index] = {
        ...lineItems[index],
        [name] : value
      };      
    }
    setLineItems(updatedLineItems);    
  }

  const handleSubmit = (e)=>{
    e.preventDefault();    
    const issueData = { issue: issue, lineItems: lineItems };
    const productItems = lineItems.filter(e=> e.product_id && e.units);
    if(productItems.length===0){
      toast.warning("Line items should not be empty! Add atleast one item to save..");
      return false;
    }
    setLoading(true);
    if(issueData.id){
      updateData('/api/issue/'+issue.id, issueData)
        .then((response) => {
          toast.success(response.message);
          props.close();
          props.refresh();
      });
    }else{
      postData('/api/issue', issueData)
        .then((response) => {
          toast.success(response.message);
          props.close();
          props.refresh();
      });
    }
  }

  const handleFarmerSearch =(e)=> {
    const searchVal = e.target.value;
    setFarmerSearchVal(searchVal);
    const filteredItems = farmers.filter(item => {
      const primaryNoMatches = item.primary_no && item.primary_no.toLowerCase().includes(searchVal.toLowerCase());
      const nameMatches = item.farmer_name && item.farmer_name.toLowerCase().includes(searchVal.toLowerCase());
      const villageMatches = item.village && item.village.toLowerCase().includes(searchVal.toLowerCase());
      const phoneMatches = item.mobile_number && item.mobile_number.toLowerCase().includes(searchVal.toLowerCase());
  
      return primaryNoMatches || nameMatches || villageMatches || phoneMatches;
    });
    setFilteredFarmers(filteredItems);
  }

  const handleFileChange = (e)=>{
    const name = e.target.name;
    const value = e.target.value;    
  }

  const handleFileSubmit = (e)=>{
    e.preventDefault();
    let files = document.getElementById("issue-files").files;
    let issueFields = document.getElementById("issue-invoice-fields");
    issueFields.disabled = true;
    let formData = new FormData();   
    
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }    

    if(issue.id){
      postFile(`/api/issue-upload/${issue.id}`, formData).then((response) => {
        toast.success(response.message);
        issueFields.disabled = false;
      });  
    }else{
      toast.warning("You need to create the invoice first!");
    }    
  }

  const selectFarmer = ()=> {
    const _farmer_id = issue._farmer_id;
    const selectedFarmer = farmers.filter(e=> e.primary_no===_farmer_id);
    getBOS(selectedFarmer[0].id);
    setSelectedOrder({
      order_id    : selectedFarmer[0].id,
      primary_no  : _farmer_id,
      project_code: selectedFarmer[0].project_code,
      project_name: selectedFarmer[0].project_name,
      invoice_no  : selectedFarmer[0].invoice_1_no+", "+selectedFarmer[0].invoice_2_no,
      invoice_date: selectedFarmer[0].invoice_date,
      farmer_name : selectedFarmer[0].farmer_name,
      village     : selectedFarmer[0].village,
      taluka      : selectedFarmer[0].taluka,
      district    : selectedFarmer[0].district,
      state       : selectedFarmer[0].state,
      mobile_number: selectedFarmer[0].mobile_number
    });
    setIssue((prevIssue) => ({
      ...prevIssue,
      farmer_id: selectedFarmer[0].id,
      invoice_no: selectedFarmer[0].invoice_1_no+", "+selectedFarmer[0].invoice_2_no,
      invoice_date: selectedFarmer[0].invoice_date,
      installer_id: selectedFarmer[0].installer_id,
      installer_code: selectedFarmer[0].installer_code,
      installer_name: selectedFarmer[0].installer_name,
    }));          
    setModalShow(false);
  }

  useEffect(()=>{
    setIssue(() => ({ 
      ...props.data, 
      state_code    :'',
      state_name    :'', 
      district_code :'', 
      district_name :''   
    }));
    getFarmers();
    getStates();
    getDistricts();
    getInstallers();   
  },[props])

  return(
    <>
      <datalist id="farmer-list">
        {farmers.filter(e=> e.state.replaceAll(' ','').toLocaleLowerCase()===issue.state_name).filter(e=> e.district.replaceAll(' ','').toLocaleLowerCase()===issue.district_name).map(f=>
          <option key={"FM"+f.id} value={f.primary_no}>{f.farmer_name}</option>
        )}
      </datalist>
      <Row xs={12} md={12} className="h-100">
        <Col xs={12} sm={6} md={4} className="pt-2">        
          <Card className="w-100 h-100 border-0 shadow">
            <Card.Header className="text-secondary fw-bold">
              Order Information
            </Card.Header>
            <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off">
              <Card.Body className="m-0 p-3">
                <Row xs={12}>                  
                  <Col xs={12} md={12} className="pb-2 mb-2">
                    <Button variant="secondary" className="rounded-0" onClick={() => setModalShow(true)}>Select Farmer / Consumer</Button>                    
                  </Col>
                </Row>
                <div className="overflow-y-scroll" style={{maxHeight: '60vh'}}>
                  <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td><label className="text-muted">Order Primary No.</label></td>
                        <td className="px-1 fw-semibold"> {selectedOrder.primary_no} </td>
                      </tr>                    
                      <tr>
                        <td><label className="text-muted">Farmer Details:</label></td>
                        <td> 
                          <h6 className="pt-1">{selectedOrder.farmer_name},</h6>
                          <h6>{selectedOrder.village}</h6>
                          <h6>{selectedOrder.taluka}</h6>
                          <h6>{selectedOrder.district}</h6>
                          <h6>{selectedOrder.state}</h6>                  
                          <h6>{selectedOrder.mobile_number}</h6>
                        </td>
                      </tr>                    
                      <tr>
                        <td><label className="text-muted">Project Code:</label></td>
                        <td className="px-1 fw-semibold"> {selectedOrder.project_code} </td>
                      </tr>                    
                      <tr>
                        <td><label className="text-muted">Project Name:</label></td>
                        <td className="px-1 fw-semibold"> {selectedOrder.project_name} </td>                    
                      </tr>
                      <tr>
                        <td><label className="text-muted">Invoice No:</label></td>                    
                        <td className="px-1 fw-semibold"> {selectedOrder.invoice_no} </td>
                      </tr>
                      <tr>
                        <td><label className="text-muted">Invoice Date:</label></td>                    
                        <td className="px-1 fw-semibold"> {selectedOrder.invoice_date?moment(selectedOrder.invoice_date).format("DD-MM-YYYY"):""} </td>
                      </tr>
                      <tr>
                        <td><label className="text-muted">Installer Code:</label></td>                    
                        <td className="px-1 fw-semibold"> {selectedOrder.installer_code} </td>
                      </tr>
                      <tr>
                        <td><label className="text-muted">Installer Name:</label></td>
                        <td className="px-1 fw-semibold"> {selectedOrder.installer_name} </td>
                      </tr>                              
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
              <div className="px-2 pb-2">
                <Button type="submit" variant="primary" className="mx-2">Save</Button>              
                <Button type="button" variant="outline-secondary" className="mx-2" onClick={props.close}>Cancel</Button>
              </div>            
            </form> 
          </Card>
        </Col>
        <Col xs={12} sm={6} md={8} className="pt-2">
          <Card className="w-100 h-100 border-0 shadow">
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} justify className="fw-semibold">
              <Tab eventKey="products" title="Order Items">
                {!isLoading?(
                  <Card.Body className="pt-0">
                    <form onSubmit={(e)=>e.preventDefault()} autoComplete="off">
                      <Row xs={12} className="mb-2">
                        <Col xs={12} md={6} lg={6}>
                          <div>
                            <label htmlFor="tr_name" className="text-muted">Transporter Name</label>
                            <input type="text" name="tr_name" className="form-control form-control-sm shadow-none rounded-0" maxLength={255} value={issue.tr_name} onChange={handleInputChange} />
                          </div>
                          <div>
                            <label htmlFor="driver_phone" className="text-muted">Driver Contact No.</label>
                            <input type="text" name="driver_phone" className="form-control form-control-sm shadow-none rounded-0" maxLength={20} value={issue.driver_phone} onChange={handleInputChange} />
                          </div>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <label htmlFor="remarks" className="text-muted">Remarks:</label>
                          <textarea name="remarks" className="form-control shadow-none rounded-0" rows={3} maxLength={255} value={issue.remarks} onChange={handleInputChange} />
                        </Col>
                      </Row>
                      <Row xs={12} className="mb-2 px-3">
                        <Col xs={4} md={3} lg={2} className="border">
                          <p className="form-label py-1 mb-0">Bill Of Supply :</p>
                        </Col>
                        <Col xs={8} md={9} lg={10} className="border bg-light">
                          <h6 className="pt-2 mb-0">{bos.bos_name}</h6>
                        </Col>
                      </Row> 
                      <ListGroup variant="flush">
                        <ListGroup.Item className="bg-secondary text-light">
                          <Row xs={12} md={12}>
                            <Col xs={5} md={5} className="">Product Name</Col>
                            <Col xs={3} md={3} className="">Serial No.</Col>
                            <Col xs={2} md={2} className="text-end">Quantity</Col>
                            <Col xs={2} md={2} className="text-end">UOM</Col>
                          </Row>
                        </ListGroup.Item>                      
                          <div className="overflow-y-scroll" style={{height: '48vh'}}> 
                            {lineItems.map((row, index) => 
                              <ListGroup.Item key={"LI"+index} className="p-0">
                                <Row xs={12} md={12} className="g-0">
                                  <Col xs={5} md={5}>
                                    <Form.Select name="product_id" size="sm" className="shadow-none rounded-0" value={row.product_id} onChange={handleLineItemsChange} dataindex={index}>
                                      {row.products.map((item, i)=>
                                        <option key={"PI"+item.product_id} value={item.product_id}>{item.product_name}</option>
                                      )}
                                    </Form.Select>
                                  </Col>
                                  <Col xs={3} md={3}>
                                    <Form.Control type="text" name="serial_no" size="sm" className="shadow-none rounded-0" value={row.serial_no} onChange={handleLineItemsChange} dataindex={index} />
                                  </Col>
                                  <Col xs={3} md={2}>
                                    <Form.Control type="number" name="units" size="sm" className="shadow-none rounded-0 text-end" value={row.units} onChange={handleLineItemsChange} dataindex={index} />
                                  </Col>
                                  <Col xs={3} md={2}>
                                    <Form.Control type="text" name="uom" size="sm" className="shadow-none rounded-0 text-end bg-light" value={row.uom} dataindex={index} readOnly />
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                            )}
                          </div>                                       
                      </ListGroup>
                    </form>
                  </Card.Body>               
                ):(
                  <>Loading ...</>
                )}
              </Tab>
              <Tab eventKey="upload" title="Invoice Upload">
                <Card.Body className="overflow-y-scroll" style={{height: '80vh'}}>                                    
                  <form id="issue-form" onSubmit={(e)=> e.preventDefault()}>
                    <fieldset id="issue-fields" className="my-0 mx-auto"> 
                      <div className="mb-3">
                        <p className="text-muted fw-light">File must be in (.png / .jpg / .jpeg) format</p>                          
                        <input type="file" id="issue-sheet" className="form-control" onChange={handleFileChange} required /> 
                      </div>
                      <div className="my-2">
                        <input type="submit" className="btn btn-outline-primary fw-bold" value="Upload and Update" />                        
                      </div>
                    </fieldset>
                  </form> 
                </Card.Body>
              </Tab>            
            </Tabs>         
          </Card>
        </Col>
      </Row> 
      <Modal 
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"        
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-secondary">
            Select Farmer / Consumer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Row>
            <Col>
              <div className="mb-3">            
                <h6>Select State</h6>
                <select className="form-select shadow-none" name="state_code" value={issue.state_code} onChange={handleInputChange}>
                  <option value=""></option>
                  {states.map(s=>
                    <option key={"SC"+s.state_code} value={s.state_code}>{s.state_name}</option>
                  )}
                </select>
              </div>
            </Col>
            <Col>
              <div className="mb-3">            
                <h6>Select District</h6>
                <select className="form-select shadow-none" name="district_code" value={issue.district_code} onChange={handleInputChange}>
                  <option value=""></option>
                  {districts.filter(e=> e.state_code==issue.state_code).map(d=>
                    <option key={"DC"+d.district_code} value={d.district_code}>{d.district_name}</option>
                  )}
                </select>
              </div>
            </Col>
          </Row>
          <div className="mb-2">
            <h6>Select Farmer</h6>                                 
            <input type="text" className="form-control shadow-none" name="farmerSearchVal" value={farmerSearchVal} onChange={handleFarmerSearch} placeholder='Search Farmer by Name, Primary No, Village, Phone No.' />
          </div>
          <div className="mb-2">            
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Village</th>
                    <th>Phone Number</th>
                    <th>Primary No</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredFarmers.map((farmer, index) => 
                    <tr key={"FF"+index}>
                      <td>
                        <input type="radio" name="_farmer_id" value={farmer.primary_no} onChange={handleInputChange} required />
                      </td>
                      <td>{farmer.farmer_name}</td>
                      <td>{farmer.village}</td>
                      <td>{farmer.mobile_number}</td>
                      <td>{farmer.primary_no}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={selectFarmer}>Select</Button>
          <Button variant="outline-secondary" onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>    
  )
}

function ViewIssue(props) {
  const [data, setData] = useState(props.data);
  const [issuedItems, setIssuedItems] = useState([]);
  const [issueFiles, setIssueFiles] = useState([]);

  const getIssueItems = async()=> {
    try{
      const response = await fetch(`/api/issue-items/${data.id}`);
      const json = await response.json();
      setIssuedItems(json.length?json:[]);
    }catch(e){
      console.error(e);
    }
  }

  const getIssueFiles = async()=> {
    try{
      const response = await fetch(`/api/issue-files/${data.id}`);
      const json = await response.json();
      setIssueFiles(json.length?json:[]);
    }catch(e){
      console.error(e);
    }
  }

  async function generateJCR() {        
    try {
      const response = await fetch(`api/jcr-report/${data.id}`);
      const htmlData = await response.text();
      var myWindow = window.open("", "JCR Report");
      myWindow.document.write(htmlData);
    } catch (error) {
      console.error(error);
      toast.error(error);
    }   
  }

  useEffect(()=>{    
    getIssueItems();
    getIssueFiles();
  },[]);

  return (
    <>
      <Row xs={12} md={12} className="h-100 g-0">
        <Col xs={12} className="p-2">          
          <div className="w-100 h-100 border-0 bg-white">
            <Card.Body className="m-0 px-3 pb-3">
              <Row xs={12} className="g-0">
                <Col xs={12} sm={6} md={6}>
                  <h5 className="py-2"> Order Information</h5>
                  <Table responsive bordered>
                    <tbody>
                      <tr>
                        <td>Project Code</td>
                        <td className="fw-semibold">{data.project_code}</td>
                      </tr>
                      <tr>
                        <td>Project Name</td>
                        <td className="fw-semibold">{data.project_name}</td>
                      </tr>
                      <tr>
                        <td>Order Date</td>
                        <td className="fw-semibold"> {moment(data.order_date).format("DD-MM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td>Invoice No</td>
                        <td className="fw-semibold"> {data.txn_no}</td>
                      </tr>
                      <tr>
                        <td>Invoice Date</td>
                        <td className="fw-semibold">{moment(data.txn_date).format("DD-MM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td>Consumer Details</td>
                        <td className="fw-semibold">
                          {data.farmer_name}, <br />
                          {data.village}, <br />
                          {data.taluka?data.taluka+" (taluka)":""}, <br />
                          {data.district}, <br />
                          {data.state} - {data.pincode}. <br />
                          Ph: {data.mobile_number}
                        </td>
                      </tr>
                      <tr>
                        <td>Empanelled Agency</td>
                        <td className="fw-semibold">{data.empanelled_agency}</td>
                      </tr>
                      <tr>
                        <td>Installation Company</td>
                        <td className="fw-semibold">{data.installer_name}</td>
                      </tr>
                      <tr>
                        <td>Install Engineers</td>
                        <td className="fw-semibold"></td>
                      </tr>                     
                    </tbody>
                  </Table>                                    
                </Col> 
                <Col xs={12} sm={6} md={6} className="px-2">
                  <h5 className="py-2"> Products Issued</h5>
                  <Table responsive striped bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Serial No</th>
                        <th>Quantity</th>                  
                        <th>UOM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {issuedItems.map((row, index)=>
                        <tr key={"II"+row.id}>
                          <td>{index+1}</td>
                          <td>{row.product_name}</td>
                          <td>{row.serial_no}</td>
                          <td>{row.units}</td>
                          <td>{row.uom}</td>
                        </tr>                      
                      )}                      
                    </tbody>
                  </Table>
                  <div className='py-2'>
                    <Button type="button" variant='primary rounded-0 fw-bold' onClick={generateJCR}>
                      <FaFileInvoice /> JCR Report
                    </Button>
                    <Button type="button" variant='outline-secondary mx-2 rounded-0 fw-bold' onClick={props.close}><FaXmark /> Close</Button>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <>
                    <div className="d-flex py-2 justify-content-between">
                      <h5> Attachments</h5>                      
                    </div>
                    <Row xs={12}>
                      {issueFiles.map((row,index)=>
                        <Col xs={12} sm={6} md={4} lg={3} key={"IF"+row.id} className="p-2">
                          <embed src="https://cdn.britannica.com/26/84526-050-45452C37/Gateway-monument-India-entrance-Mumbai-Harbour-coast.jpg" width="200px" height="auto" />
                          <a className="text-primary fw-semibold m-2" href={row.file_path} target="_blank">
                            Open File
                          </a>
                          <br />                  
                        </Col>
                      )}                      
                    </Row>
                  </>            
                </Col>                                      
              </Row>              
            </Card.Body>
          </div>
        </Col>                  
      </Row>
    </>
  );
}


export default Issues;